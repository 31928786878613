/* .MuiGrid-grid-xs-1
{
  flex-basis: 11% !important;
  max-width: 11% !important;
}

.MuiGrid-grid-xs-2
{
  flex-basis: 14% !important;
  max-width: 14% !important;
} */

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation0.MuiPaper-rounded {
  border-radius: 8px !important;
}

.MuiAccordionDetails-root
{
  display:block;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
  height:100%;
}
#iconButton:hover{
  cursor: pointer;
}

.Mui-expanded {
  min-height: 10px !important;
  /* margin: 12px 0 !important; */
}

.MuiAccordionSummary-content.Mui-expanded{
  margin: 12px 0 !important;
}
/* 
.MuiAccordionDetails-root {
  padding: 8px 16px 8px !important;
} */